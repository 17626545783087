import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';

import {getLocaleI18nResource, getLocaleStored, LANG_DEFAULT, setLocaleStored} from './utils';

export const IntlContext = React.createContext({
    locale: LANG_DEFAULT,
    selectLanguage: (e: string) => {
        console.log(e);
    },
});


const IntlWrapper: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const local = getLocaleStored() ?? LANG_DEFAULT;
    const lang = getLocaleI18nResource(local);
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLanguage(e: string) {
        setLocale(e);
        setLocaleStored(e);
        setMessages(getLocaleI18nResource(e));
    }

    return (
        <IntlContext.Provider value={{locale, selectLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                {children}
            </IntlProvider>
        </IntlContext.Provider>
    );
};
export default IntlWrapper;
