import English from './en.json';
import Spanish from './es.json';

const LOCALE_STORAGE_VAR = 'intl';

export const LANG_DEFAULT = 'en';
export const LANG_SUPPORTS = ['es', 'en'];

export const getLocaleFromBrowser = () => {
    if (typeof navigator != 'undefined' && navigator.language) {
        if (navigator.language.includes('en')) {
            return 'en';
        }
        if (navigator.language.includes('es')) {
            return 'es';
        }
    }
    return LANG_DEFAULT;
};

export const getLocaleStored = () =>
    localStorage.getItem(LOCALE_STORAGE_VAR) ?? getLocaleFromBrowser();

export const setLocaleStored = (locale: string) => localStorage.setItem(LOCALE_STORAGE_VAR, locale);

export const getLocaleI18nResource = (locale: string): Record<string, string> =>
    locale === 'en' ? English : Spanish;
