export interface IGlobalState {
    loading: boolean;
    error: string | undefined;
}

const initialState: IGlobalState = {
    loading: false,
    error: undefined,
};

export default initialState;
