import React, {useState} from 'react';
import AppLogo from "../../../../components/commons/logo/Logo";
import {useNavigate} from "react-router-dom";
import LandingHeaderLink from "../landingHeaderLink/LandingHeaderLink";
import I18nSelector from "../i18nSelector/I18nSelector";
import {MenuIcon as Icon} from '@heroicons/react/outline';
import {Menu, MenuHandler, MenuList,} from "@material-tailwind/react";
import {Link} from "../../../../core/types/link";

const LandingHeader: React.FC = () => {
    const navigate = useNavigate();
    const [option, setOption] = useState("");

    const links: Link[] = [
        {label: 'about_us', url: '/about'},
        {label: 'partner_information', url: '/partner'},
        {label: 'legal_information', url: '/legal'}
    ];

    const onSelect = (optionSelected: string) => {
        setOption(optionSelected);
    }

    return (
        <header className="flex-shrink-0 p-4 bg-main-black h-24 gap-4 flex">
            <div className={"w-[50%] xl:w-[30%] flex justify-start items-center"}>
                <AppLogo onClick={() => navigate('/')}/>
            </div>
            <div
                className={"w-[50%] flex-grow flex justify-end items-center text-white text-base gap-x-16 py-4 hidden xl:flex"}>
                {links?.map((link, index) => (
                    <LandingHeaderLink key={index} {...{
                        link,
                        selected: option === link.label,
                        onSelect
                    }}/>
                ))}
            </div>
            <div className={"w-[50%] xl:w-[8%] flex justify-end items-center"}>
                <I18nSelector/>
                <div className="w-6 h-6 text-white ml-4 flex xl:hidden bg-main-black z-50">
                    <Menu placement="bottom-end">
                        <MenuHandler>
                            <Icon className="w-6 h-6 text-white"/>
                        </MenuHandler>
                        <MenuList>
                            {links?.map((link, index) => (
                                <LandingHeaderLink key={index}  {...{
                                    link,
                                    asMenuItem: true,
                                    selected: option === link.label,
                                    onSelect
                                }}/>
                            ))}
                        </MenuList>
                    </Menu>
                </div>

            </div>
        </header>
    );
};

export default LandingHeader;
