import React from 'react';
import {Route, Routes} from 'react-router-dom';

const IndexPage = React.lazy(() => import('../../pages/index'));
const AboutUsPage = React.lazy(() => import('../../pages/aboutUs'));
const PartnerInfoPage = React.lazy(() => import('../../pages/partnerInfo'));
const LegalInfoPage = React.lazy(() => import('../../pages/legalInfo'));

function AppRoutes() {
    return (
        <React.Suspense>
            <Routes>
                <Route path="/" element={<IndexPage/>}/>
                <Route path="/about" element={<AboutUsPage/>}/>
                <Route path="/partner" element={<PartnerInfoPage/>}/>
                <Route path="/legal" element={<LegalInfoPage/>}/>
                <Route path="*" element={<>Not Found</>}/>
            </Routes>
        </React.Suspense>
    );
}

export default AppRoutes;
