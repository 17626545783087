import './assets/css/base.css';
import "./App.css";
import AppRoutes from "./core/routes/Routes";
import ReactGA from 'react-ga4';
import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";
import {GlobalProvider} from "./core/stores/global";
import LandingBaseLayout from "./template/landingBaseLayout";

const appCache = createCache({
    key: 'evericore',
});

function App() {
    ReactGA.initialize(process.env?.REACT_APP_GA_MEASURE_ID ?? '');
    ReactGA.send('pageview');
    return (
        <CacheProvider value={appCache}>
            <GlobalProvider>
                <LandingBaseLayout>
                    <AppRoutes/>
                </LandingBaseLayout>
            </GlobalProvider>
        </CacheProvider>
    );
}

export default App;
