import React from 'react';
import {FormattedMessage} from "react-intl";

type Props = {
    year?: number;
    name?: string;
};

const LandingFooter: React.FC<Props> = (
    {
        year = new Date().getFullYear(),
        name = process.env.REACT_APP_NAME
    }) => (
    <footer
        className="flex-shrink-0 p-4 bg-main-black flex items-center justify-center text-white h-24 font-normal text-xs">
        <div>
            <FormattedMessage id="copyright" values={{year, name,}}/>
        </div>
    </footer>
);


export default LandingFooter;
