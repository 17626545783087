import React from 'react';
import LandingHeader from "../modules/landing/components/landingHeader/LoadingHeader";
import LandingFooter from "../modules/landing/components/landingFooter/LandingFooter";
import BaseLayout from "./baseLayout";

type Props = {
    children: React.ReactNode;
};

const LandingBaseLayout: React.FC<Props> = ({children}) => {
    return (
        <BaseLayout>
            <div className="bg-main-black min-h-screen flex  justify-center  ">
                <div className="bg-main-black flex flex-col grow container px-0 md:px-24 xl:px-32">
                    <LandingHeader/>
                    <main className="flex-auto shrink-0 bg-main-black relative">
                        {children}
                    </main>
                    <LandingFooter/>
                </div>
            </div>
        </BaseLayout>
    );
};

export default LandingBaseLayout;
