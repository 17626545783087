import React from 'react';
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";
import {Link} from "../../../../core/types/link";
import {MenuItem} from "@material-tailwind/react";

type Props = {
    link: Link;
    asMenuItem?: boolean;
    applyI18n?: boolean;
    selected?: boolean;
    onSelect?: (option: string) => void;
};

const LandingHeaderLink: React.FC<Props> = ({
                                                link,
                                                asMenuItem = false,
                                                applyI18n = true,
                                                selected = false,
                                                onSelect
                                            }) => {
    const navigate = useNavigate();
    const onLinkClick = (link: Link) => {
        onSelect?.(link.label);
        navigate(link.url);
    }
    const renderLink = (isRef = true, border = true) => {
        return <a
            className={`pb-1 pt-1 text-white cursor-pointer ${selected && 'font-bold'} ${border && selected && 'border-b'}`}
            onClick={() => isRef && onLinkClick(link)}>
            {applyI18n ? (<FormattedMessage id={link.label}/>) : link.label}
        </a>
    }

    return (
        <>
            {asMenuItem ? (
                    <MenuItem className={"px-4 py-2"} onClick={() => onLinkClick(link)}>
                        {renderLink(false, false)}
                    </MenuItem>
                ) :
                renderLink()
            }
        </>
    );
};

export default LandingHeaderLink;
