import {CacheProvider} from '@emotion/react';
import React from 'react';
import {ScreenClassProvider, setConfiguration} from 'react-grid-system';
import createCache from '@emotion/cache';
import IntlWrapper from "../intl/wrapper";
import Seo from "./seo";


type Props = {
    title?: string;
    description?: string;
    children: React.ReactNode;
};

setConfiguration({defaultScreenClass: 'sm'});

const emotionCache = createCache({
    key: 'evericore-portal',
    nonce: `${process.env.REACT_APP_NONCE}`,
});

const BaseLayout: React.FC<Props> = (
    {
        title = `${process.env.REACT_APP_NAME} Landing Page`,
        description = `${process.env.REACT_APP_NAME} Landing Page`,
        children
    }) => {
    return (
        <CacheProvider value={emotionCache}>
            <IntlWrapper>
                <ScreenClassProvider>
                    <Seo title={title ?? ''} description={description ?? ''}/>
                    {children}
                </ScreenClassProvider>
            </IntlWrapper>
        </CacheProvider>
    );
};

export default BaseLayout;
