import React, {useContext, useEffect, useState} from 'react';
import {IntlContext} from '../../../../intl/wrapper';
import {GlobeAltIcon as Icon} from '@heroicons/react/outline';
import {useSearchParams} from 'react-router-dom';
import {getLocaleStored, LANG_SUPPORTS} from '../../../../intl/utils';

const I18nSelector = () => {
    const intl = useContext(IntlContext);
    const {locale, selectLanguage} = intl;
    const [value, setValue] = useState(locale);
    const [searchParams] = useSearchParams();
    const icon = <Icon className="h-6 w-6 text-white"/>;

    const changeLang = (lang: string) => {
        const langSupport = !LANG_SUPPORTS.includes(lang) ? getLocaleStored() : lang;
        setValue(langSupport);
        selectLanguage(langSupport);
    };

    const onChange = (e: any) => {
        changeLang(e.target.value);
    };

    useEffect(() => {
        const langFromRoute = searchParams.get('lang');
        if (langFromRoute) {
            changeLang(langFromRoute);
        }
    }, []);

    return (
        <div className="bg-transparent flex items-center ml-12">
            {icon}
            <select {...{onChange, value}} className="bg-transparent border-0 text-white pl-2">
                {LANG_SUPPORTS.map((lang) => (
                    <option key={lang} value={lang} className="text-black">
                        {lang.toUpperCase()}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default I18nSelector;
