import React from 'react';

import Logo from '../../../assets/svg/logo-evericore-white.svg';


interface Props {
    onClick?: () => void;
}

const AppLogo: React.FC<Props> = ({onClick}) => {
    return (
        <div
            className="cursor-pointer flex justify-center items-center"
            onClick={onClick}
            role="button"
            tabIndex={0}
            onKeyUp={onClick}
        >
            <img
                src={Logo}
                alt={`${process.env.REACT_APP_NAME} Logo`}
            />
        </div>
    );
};

export default AppLogo;
