import {TAction} from './actions';
import {IGlobalState} from './initialState';
import * as types from './types';

const reducer = (state: IGlobalState, action: TAction): IGlobalState => {
    const {type} = action;
    switch (type) {
        case types.CLEAR_ERROR:
            return {
                ...state,
                error: undefined,
            };
        case types.LOADING:
            return {...state, loading: action?.payload};
        default:
            return state;
    }
};
export default reducer;
