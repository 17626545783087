import React, {useContext} from 'react';
import {Helmet} from 'react-helmet';
import {IntlContext} from '../intl/wrapper';

type Props = {
    title: string;
    description: string;
};

const Seo: React.FC<Props> = ({title}) => {
    const intlContext = useContext(IntlContext);
    const lang = intlContext.locale;

    return (
        <Helmet
            htmlAttributes={{lang}}
            title={title}
            titleTemplate={title}
        />
    );
};

export default Seo;
